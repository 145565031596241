<template>
  <v-container
    id="dashboard"
    tag="section"
    fluid
  >
  </v-container>
</template>

<script>
export default {
  name: 'Home'
}
</script>